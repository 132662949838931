import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useParams } from "react-router-dom";
import { baseurl } from "../Constatnts/Constanturls";
import axios from "axios";
import { v1 as uuidv1 } from 'uuid';
import { useNavigate } from "react-router-dom";
var CryptoJS = require("crypto-js");


function ProductPage() {
  let navigate = useNavigate()

  var Time_now  = (new Date()).getTime();

  const params = useParams();


  const [allcities, setallcities] = useState([]);

  const [productshowimage, setproductshowimage] = useState("");

  const [colour, setcolour] = useState("")
  const [size, setsize] = useState("")

  const [order,setoder] = useState({
    clientname : "",
    phone : "",
    city : "",
    quantity : "",
    address : "",
    size : "",
    colour : "",
   

  })

  const [product, setproduct] = useState({
    name: "",
    category: "",
    brand: "",
    title: "",
    code: "",
    purchase_price: "",
    price: "",
    offer_price: "",
    deliverycharge: "",
    size: "",
    colour: "",
    quantity: "",
    fake_order_sold: "",
    rank: "",
    description: "",
    status: "",
    product_image: "",
    id : "",
  });

  const [moreimage, setmoreimage] = useState([]);

  useEffect(() => {
  if (product.name == ''){
    Productdata();
    Allcity();
    LocalstorageClear();
  }
  else{
    MissingOrder()
  }
   
  }, [order.clientname,order.phone,order.city]);

  const Allcity = () => {
    
   
      axios.get(`${baseurl}/StatusApp/cities/`).then((res) => {
        setallcities(res.data);
      });
  
  };

  const Productdata = () => {
  

    axios
      .get(`${baseurl}/ProductApp/productuser/`, {
        params: {
          id: params.id,
        },
      })
      .then((res) => {
        console.log(res.data);
        setproduct({
          name: res.data[0].title,
          product_image: res.data[0].product_image,
          price: res.data[0].price,
          offer_price: res.data[0].offer_price,
          description: res.data[0].description,
          deliverycharge: `${res.data[0].deliverycharge == null ? '0' : `${res.data[0].deliverycharge}`}`,
          code: res.data[0].code,
          id: res.data[0].id,

        });
        setproductshowimage(res.data[0].product_image);
        setmoreimage(res.data[0].productimage);
         if (res.data[0].colour != ""){
            var coloursplit = res.data[0].colour.split(',')
            setcolour(coloursplit)
         }
         else {
           setcolour([])
         }
         if (res.data[0].size != "") {
          var sizesplit = res.data[0].size.split(',')
          setsize(sizesplit)
       
         }
         else{
           setsize([])
         }
     
     
      
      });
  };

  const MissingOrder = () => {
    const id = uuidv1();
     if (product.id != '' & order.clientname != '' & order.phone != '' & order.city != ''){
      var data = {
        'product' : product.id,
        'clientname' : order.clientname,
        'phone' : order.phone,
        'city' : order.city,
        'userrandomid' : id,
        'size' : order.size,
        'colour' : order.colour,
      }
      axios.post(`${baseurl}/OrderApp/missingorder/`,data)
      .then((res)=>{
        console.log(res.data)
      })
    }
  }
 

  const PlaceOrderwithdatas = () => {

    if (order.clientname == '' || order.phone == '' || order.city == '' || order.quantity == '' || order.address == '') {
        alert("PLease Fill Details")
    }
    else if(order.phone.length != 10){
      alert("PLease Fill Valid phone")

    }
    else{

   
    const id = uuidv1();
    var product_total = `${product.offer_price == null || product.offer_price == 0 ? `${product.price}` * `${order.quantity}`:`${product.offer_price}` * `${order.quantity}`}`
    var VAT = (product_total * 0.05)
    var data = {
      'clientname' : order.clientname,
      "phone" : order.phone,
      'userrandomid':id ,
      'city': order.city ,
      'quantity': order.quantity ,
      'address': order.address ,
      'product_code':product.code ,
      'product_name':product.name ,
      'product_price':`${product.offer_price == null || product.offer_price == 0 ? `${product.price}`:`${product.offer_price}`}` ,
      'deliverycharge': product.deliverycharge ,
      'product_VAT': VAT,
      'product_total': (Number(product_total) + Number(VAT) + Number(product.deliverycharge)),
      'product_size': order.size,
      'product_colour': order.colour,
      'product_image_url' : product.product_image,
      'product_id' : product.id,
    
    }
    axios.post(`${baseurl}/OrderApp/allorder/`,data).then((res)=>{
      var data = {
        'clientname' : order.clientname,
        "phone" : order.phone,
        'userrandomid':id ,
        'city': order.city ,
        'address': order.address ,
      }
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), 'my-secret-key@123').toString();
      localStorage.setItem("Data" ,ciphertext)
      localStorage.setItem("Lastclear" ,Time_now)
      return navigate(`/checkout/${id}`)

    })
  }
  }

  const PlaceOrderwithlocalstorage = () => {
    var ciphertext = localStorage.getItem("Data");
    var bytes = CryptoJS.AES.decrypt(ciphertext, 'my-secret-key@123');
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    var product_total = `${product.offer_price == null || product.offer_price == 0 ? `${product.price}` * `${order.quantity}`:`${product.offer_price}` * `${order.quantity}`}`
    var VAT = (product_total * 0.05)

    var data = {
      'clientname' : decryptedData.clientname,
      "phone" : decryptedData.phone,
      'userrandomid':decryptedData.userrandomid ,
      'city': decryptedData.city ,
      'quantity': order.quantity ,
      'address': decryptedData.address ,
      'product_code':product.code,
      'product_name':product.name,
      'product_price':`${product.offer_price == null || product.offer_price == 0 ? `${product.price}`:`${product.offer_price}`}` ,
      'deliverycharge': product.deliverycharge ,
      'product_VAT': VAT,
      'product_total': (Number(product_total) + Number(VAT) + Number(product.deliverycharge)),
      'product_size': order.size,
      'product_colour': order.colour,
      'product_image_url' : product.product_image,
      'product_id' : product.id,
    
    }
    axios.post(`${baseurl}/OrderApp/allorder/`,data).then((res)=>{
      console.log(res.data.Message)
      return navigate(`/checkout/${decryptedData.userrandomid}`)
    })
  
  }

const ChangeHandler = (e) => {
    setoder({ ...order, [e.target.name]: e.target.value });
  };

const LocalstorageClear = () => {
   var Lastclear = localStorage.getItem('Lastclear')
  if ((Time_now - Lastclear) > 1000 * 60 * 60 * .05  ) {
    localStorage.clear()
  }
}

  return (
    <div>
      <Header />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="product-details">
                {/*product-details*/}
                <div className="col-sm-10">
                  <div className="view-product">
                    <img
                      src={`${productshowimage}`}
                      alt
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                  {moreimage.length == 0 ? (
                    ""
                  ) : (
                    <>
                      <div
                        id="similar-product"
                        className="carousel slide"
                        data-ride="carousel"
                      >
                        {/* Wrapper for slides */}
                        <div className="carousel-inner">
                          <div className="item active">
                            <img
                              src={product.product_image}
                              alt
                              width="100px"
                              height="150px"
                              style={{ objectFit: "contain" }}
                              onClick={(e) => setproductshowimage(e.target.src)}
                            />
                            {moreimage.map((value, key) => (
                              <img
                                src={`${baseurl}${value.productimage}`}
                                alt
                                width="100px"
                                height="150px"
                                style={{ objectFit: "contain" }}
                                onClick={(e) =>
                                  setproductshowimage(e.target.src)
                                }
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="col-sm-"></div>
              </div>
              {/*/product-details*/}
            </div>
            <div className="col-sm-4 padding-right">
              <div className="bill-to">
                <p style={{ fontWeight: "bolder", fontSize: "30px" }}  >
                  {product.name}
                </p>
                <p style={{ fontWeight: "bolder", fontSize: "20px" }}>
                  {product.offer_price == null || product.offer_price == 0
                    ? `${product.price} AED`
                    : `${product.offer_price} AED`}
                </p>

              {localStorage.getItem('Data') ? <>
              <form>
              <label>Quantity</label>
                  <br />
                  <select className="form-control"  name="quantity" onChange={ChangeHandler}  >
                    <option selected hidden>
                      Choose Quantity
                    </option>
                    <option value={1}>
                      1 -{" "}
                      {1 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={2}>
                      2 -{" "}
                      {2 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={3}>
                      3 -{" "}
                      {3 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={4}>
                      4 -{" "}
                      {4 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={5}>
                      5 -{" "}
                      {5 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={6}>
                      6 -{" "}
                      {6 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={7}>
                      7 -{" "}
                      {7 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={8}>
                      8 -{" "}
                      {8 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={9}>
                      9 -{" "}
                      {9 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={10}>
                      10 -{" "}
                      {10 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                  </select>
                  {colour.length == 0 ? '' : <>
                  <label>Colour</label><br/>
                  <select className="form-control" name="colour" onChange={ChangeHandler} >
                    <option hidden defaultChecked> -- Choose Colour --</option>
                    {colour.map(value=>
                        <option value={value}>{value}</option>
                      )}
                  </select>
                  </>}<br/>
                  {size.length == 0 ? '' : <>
                  <label>Size</label><br/>
                  <select className="form-control" name="size" onChange={ChangeHandler} >
                    <option hidden defaultChecked> -- Choose Size --</option>
                    {size.map(value=>
                        <option value={value}>{value}</option>
                      )}
                  </select>
                  </>}<br/>
                  <br></br>
                  <button
                    type="button"
                    onClick={PlaceOrderwithlocalstorage}
                    className="btn form-control "
                    style={{ backgroundColor: "green", color: "white" }}
                  >
                    Submit Order
                  </button>
              </form>
              </> : 

                <form>
                  <label>Full Name</label>
                  <br />
                  <input type="text" className="form-control" name="clientname" value={order.clientname} onChange={ChangeHandler} ></input>
                  <br />
                  <label>Mobile</label>
                  <br />
                  <input type="number" maxLength={9} className="form-control" value={order.phone} name="phone" onChange={ChangeHandler} ></input>
                  <br />
                  <label>Quantity</label>
                  <br />
                  <select className="form-control"  name="quantity" onChange={ChangeHandler}  >
                    <option selected hidden>
                      Choose Quantity
                    </option>
                    <option value={1}>
                      1 -{" "}
                      {1 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={2}>
                      2 -{" "}
                      {2 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={3}>
                      3 -{" "}
                      {3 *
                        `${ 
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={4}>
                      4 -{" "}
                      {4 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={5}>
                      5 -{" "}
                      {5 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={6}>
                      6 -{" "}
                      {6 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={7}>
                      7 -{" "}
                      {7 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={8}>
                      8 -{" "}
                      {8 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={9}>
                      9 -{" "}
                      {9 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                    <option value={10}>
                      10 -{" "}
                      {10 *
                        `${
                          product.offer_price == null || product.offer_price == 0
                            ? `${product.price} `
                            : `${product.offer_price}`
                        }`}{" "}
                      AED
                    </option>
                  </select><br/>
                  {colour.length == 0 ? '' : <>
                  <label>Colour</label><br/>
                  <select className="form-control" name="colour" onChange={ChangeHandler} >
                    <option hidden defaultChecked> -- Choose Colour --</option>
                    {colour.map(value=>
                        <option value={value}>{value}</option>
                      )}
                  </select>
                  </>}<br/>
                  {size.length == 0 ? '' : <>
                  <label>Size</label><br/>
                  <select className="form-control" name="size" onChange={ChangeHandler} >
                    <option hidden defaultChecked> -- Choose Size --</option>
                    {size.map(value=>
                        <option value={value}>{value}</option>
                      )}
                  </select>
                  </>}<br/>
                  <label>Emirates</label>
                  <br />
                  <select className="form-control"  name="city" onChange={ChangeHandler}   >
                    <option selected hidden>
                      Choose Emirates
                    </option>
                    {allcities.map((value, key) => (
                      <option value={value.name}>
                        {value.name} -{" "}
                        {`${
                          product.deliverycharge == null ||
                          product.deliverycharge == 0
                            ? "Free Delivery"
                            : `Delivery Charge - ${product.deliverycharge}`
                        }`}
                      </option>
                    ))}
                  </select>
                  <br />

                  <label>Delivery Address</label>
                  <br />
                  <textarea className="form-control" value={order.address} name="address" onChange={ChangeHandler}  />
                  <br />

                  <button
                    type="button"
                    onClick={PlaceOrderwithdatas}
                    className="btn form-control "
                    style={{ backgroundColor: "green", color: "white" }}
                  >
                    Submit Order
                  </button>
                </form>

                }


              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="row">
        <div className="col-md-12">
          <h3>
            <center >Description</center>
            {/* <center onClick={()=>id()}>Description</center> */}
          </h3>
          <p
            style={{
              fontSize: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            {product.description}
          </p>
        </div>
      </div>
    
    </div>
  );
}

export default ProductPage;
