import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import { baseurl } from '../Constatnts/Constanturls';



function CheckoutPage() {

  const params = useParams();

  const [ordereddetails, setordereddetails] = useState([])
  const [producttotal, setproducttotal] = useState("")
  const [productvattotal, setproductvattotal] = useState("")

  const [productdeliverycharge, setproductdeliverycharge] = useState("")

    
  useEffect(() => {
    
    OrderDatas()
  
   
  }, [])

  const OrderDatas = () => {
    axios.get(`${baseurl}/OrderApp/allorder/`,{
      params : {
        userrandomid : params.id
      }
    })
    .then((res) => {
      setordereddetails(res.data.results)
      console.log(res.data.results)
    var product_total = 0
    var vat_total = 0
    var deliverycharge = 0
    
      for (var i = 0 ;i<res.data.results.length ;i++) {
        product_total = Number(product_total) + Number(res.data.results[i].product_price * Number(res.data.results[i].quantity))
        vat_total = Number(vat_total) + Number(res.data.results[i].product_VAT)
      
        console.log("Delivery Charge Assigned" , deliverycharge)
        if (res.data.results[i].deliverycharge > deliverycharge ) {
          console.log("Entered")
          deliverycharge = res.data.results[i].deliverycharge
        }
        else {
        
        }
      }
      setproducttotal(product_total)
      setproductvattotal(vat_total)
      setproductdeliverycharge(deliverycharge)
    })
  }
  


 
  return (
    <div>
        <Header />
      <section id="cart_items">
  <div className="container">
  
    <div className="review-payment">
      <h2>Review &amp; Payment</h2>
    </div>
    <div className="table-responsive cart_info">
      <table className="table table-condensed">
        <thead>
          <tr className="cart_menu">
            <td className="image">Item</td>
            <td className="description" />
            <td className="quantity">Quantity</td>
            <td className="price">Price</td>
 
         
            <td />
          </tr>
        </thead>
        <tbody>
         
         {ordereddetails.length == 0 ? 'No Ordered' : <>
         {ordereddetails.map((value,key)=>(
               <tr>
               
               <td className="cart_product">
                 <a ><img src={value.product_image_url} width="150px" height="200px" style={{"objectFit":"contain"}} alt /></a>
               </td>
               <td className="cart_description">
                 <h4><a href>{value.product_name}</a></h4><br/>
                 <p>Order ID: {value.orderid}</p>
                 <p>Product price: {value.product_price}</p>
               </td>
               <td className="cart_quantity">
                 <div className="cart_quantity_button">
                 
                   <input className="cart_quantity_input" type="text" name="quantity" value={value.quantity} autoComplete="off" size={2} />
                   
                 </div>
               </td>
               <td className="cart_price">
                 <p>{`${value.product_price}` * `${value.quantity}`} AED</p>
               </td>
             </tr>
         ))}
         </>}   
          <tr>
            <td colSpan={4}>&nbsp;</td>
            <td colSpan={2}>
              <table className="table table-condensed total-result">
                <tbody><tr>
                    <td>Cart Sub Total</td>
                    <td>{producttotal} AED</td>
                  </tr>
                  <tr>
                    <td>VAT</td>
                    <td>{productvattotal} AED</td>
                  </tr>
                  <tr className="shipping-cost">
                    <td>Shipping Cost</td>
                    <td>{productdeliverycharge} AED</td>										
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td><span>{Number(producttotal) + Number(productvattotal) + Number(productdeliverycharge)} AED</span></td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="payment-options" >
        <span style={{"float" :"left"}}><div className='alert alert-success'> Delivery With in 2 Working days </div></span>
      <Link to='/'><button className='btn btn-warning' style={{"float":'right'}}> Continue Shopping</button></Link>
    </div>
  </div>
</section> 

    </div>
  )
}

export default CheckoutPage