import React from 'react'
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import CheckoutPage from '../Pages/CheckoutPage';
import HomePage from '../Pages/HomePage';
import ProductPage from '../Pages/ProductPage';



function AllRoute() {
  return (
    <div>
        <Router>
           <Routes>

               
                <Route path="/" element={<HomePage />} />
                <Route path="/product/:id/" element={<ProductPage />} />
                <Route path="/checkout/:id" element={<CheckoutPage />} />
                             
            </Routes>
           </Router>
    </div>
  )
}

export default AllRoute