import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import Slider from '../Components/Slider'
import axios from 'axios'
import { baseurl } from '../Constatnts/Constanturls'
import { Link } from "react-router-dom";


function HomePage() {

    const [allproduct, setallproduct] = useState([])
    const [allcategory, setallcategory] = useState([])



    useEffect(() => {      
        Allproduct(null) 
        AllCategory()
       
    }, [])

   

    const Allproduct = (categoryid) => {
        if (categoryid == null){
            axios.get(`${baseurl}/ProductApp/productuser/`,{
                params:{
                    category : ""
                }
            })
            .then((res)=>{
                console.log(res.data)
                setallproduct(res.data)
            })
        }
        else{
            axios.get(`${baseurl}/ProductApp/productuser/`,{
                params:{
                    category : categoryid
                }
            })
            .then((res)=>{
                console.log(res.data)
                setallproduct(res.data)
            })
        }
        
    }

    const AllCategory = () => {
        
        axios.get(`${baseurl}/ProductApp/categoryall/`)
        .then((res)=>{
            console.log(res.data)
            setallcategory(res.data)
        })
        
    }

       
  return (
    <div>
        <Header/>
        <Slider/>
        <div>
  <section>
    <div className="container">
      <div className="row">
        <div className="col-sm-3">
          <div className="left-sidebar">
            <h2>Category</h2>
            <div className="panel-group category-products" id="accordian">
              
              {allcategory.length == 0 ? '' :
                <>
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title" onClick={()=>Allproduct(null)}><a style={{"cursor":"pointer"}}>ALL</a></h4>
                    </div>
                </div>
                {allcategory.map((value,key)=>(
                    
                <div className="panel panel-default">
                    <div className="panel-heading">
                        <h4 className="panel-title" onClick={()=>Allproduct(value.id)}><a style={{"cursor":"pointer"}}>{value.name}</a></h4>
                    </div>
                </div>
                ))}
                </>
              }
     
            </div>



            {/* <div className="brands_products">
              <h2>Brands</h2>
              <div className="brands-name">
                <ul className="nav nav-pills nav-stacked">
                  <li><a href="#"> <span className="pull-right">(50)</span>Acne</a></li>
                  <li><a href="#"> <span className="pull-right">(56)</span>Grüne Erde</a></li>
                  <li><a href="#"> <span className="pull-right">(27)</span>Albiro</a></li>
                  <li><a href="#"> <span className="pull-right">(32)</span>Ronhill</a></li>
                  <li><a href="#"> <span className="pull-right">(5)</span>Oddmolly</a></li>
                  <li><a href="#"> <span className="pull-right">(9)</span>Boudestijn</a></li>
                  <li><a href="#"> <span className="pull-right">(4)</span>Rösch creative culture</a></li>
                </ul>
              </div>
            </div> */}

          </div>
        </div>
        <div className="col-sm-9 padding-right">
          <div className="features_items">
            <h2 className="title text-center">Features Items</h2>
            


            {allproduct.length == 0 ?<div className="col-md-12">No Data Found</div> : 
           
           <>        
           {allproduct.map((value,key)=>(
                   <div className="col-sm-4">
                   <div className="product-image-wrapper" style={{"height":"450px"}} >
                     <div className="single-products">
                       <div className="productinfo text-center">
                         <img src={value.product_image} alt style={{"height":"200px","objectFit":"contain"}} />
                         <h2>{value.title}</h2>
                        
                         {value.offer_price == null || value.offer_price == 0 ? <>
                            <h3>{value.price}</h3>
                           
                         </> : 
                         <>
                         <h3>{value.offer_price}</h3>
                         <p><strike>{value.price}</strike></p>
                        </> 
                         }
                         <p>{value.description}</p>
                         <Link to={`/product/${value.id}/`} className="btn btn-default add-to-cart">Buy Now</Link>
                       </div>
                       {/* <div className="product-overlay">
                         <div className="overlay-content">
                         <h2>{value.title}</h2>
                        
                        {value.offer_price == null ? <>
                           <h3>{value.price}</h3>
                          
                        </> : 
                        <>
                        <h3>{value.offer_price}</h3>
                        <p><strike>{value.price}</strike></p>
                       </> 
                        }
                        <p>{value.description}</p>
                        <Link to={`/product/${value.id}/`} className="btn btn-default add-to-cart">Buy Now</Link>
                         </div>
                       </div> */}
                     </div>
                    
                   </div>
                 </div>
           ))}        

            </>

                        

            }


            {/* <div class="col-sm-4">
							<div class="product-image-wrapper">
								<div class="single-products">
									<div class="productinfo text-center">
										<img src="images/shop/product12.jpg" alt="" />
										<h2>$56</h2>
										<p>Easy Polo Black Edition</p>
										<a href="#" class="btn btn-default add-to-cart"><i class="fa fa-shopping-cart"></i>Add to cart</a>
									</div>
									<div class="product-overlay">
										<div class="overlay-content">
											<h2>$56</h2>
											<p>Easy Polo Black Edition</p>
											<a href="#" class="btn btn-default add-to-cart"><i class="fa fa-shopping-cart"></i>Add to cart</a>
										</div>
									</div>
								</div>
								<div class="choose">
									<ul class="nav nav-pills nav-justified">
										<li><a href=""><i class="fa fa-plus-square"></i>Add to wishlist</a></li>
										<li><a href=""><i class="fa fa-plus-square"></i>Add to compare</a></li>
									</ul>
								</div>
							</div>
						</div> */}
            
          
    
          </div>
        
     
        </div>
      </div>
    </div>
  </section>
</div>
    </div>
  )
}

export default HomePage